<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="拼团列表"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="6">
              <div class="search_item">
                <div>活动名称：</div>
                <el-input v-model="searchData.activityName"></el-input>
              </div>
            </el-col>
            <el-col :span="4">
              <div class="search_item">
                <div>活动状态：</div>
                <el-select v-model="searchData.status" placeholder="请选择" clearable style="width: 100%;">
                  <el-option label="未开始" :value="1"></el-option>
                  <el-option label="进行中" :value="2"></el-option>
                  <el-option label="已结束" :value="3"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="search_item">
                <div>活动时间：</div>
                <el-date-picker
                  v-model="activityDate"
                  @change="activityDateSel"
                  style="width: 100%;"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20" style="text-align: end;">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/management/groupBuying/groupActivity/add">
            <el-button type="primary">新建拼团</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="活动ID" fixed="left" prop="groupActivityId" :min-width="130"></el-table-column>
      <el-table-column label="活动名称" prop="activityName" :min-width="120"></el-table-column>
      <el-table-column label="开团数量" prop="coverNum" :min-width="100">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoTeamList(scope.row)">{{ scope.row.groupCount }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="成团数量" prop="groupSuccessNum" :min-width="120"></el-table-column>
      <el-table-column label="拼团类型" prop="typeStr" :min-width="120"></el-table-column>
      <el-table-column label="活动状态" prop="statusStr" :min-width="100"></el-table-column>
      <el-table-column label="活动时间" prop="ctime" :min-width="220">
        <template slot-scope="scope">
          <div>开始时间：{{ scope.row.startTime }}</div>
          <div>结束时间：{{ scope.row.endTime }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="180">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">查看</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">编辑</el-button>
          <el-button v-if="scope.row.status==1" class="handle_btn" type="text" size="mini" @click="tableHandle(3, scope.row)">结束</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(4, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        listData: [{},{},{}],
        // 搜索数据
        searchData: {
          activityName: "",         //活动名称
          status: "",               //活动状态
          startTime: "",            //活动开始时间
          endTime: "",              //活动结束时间
        },
        listLoading: false, //列表loading        rewardDialogVisible: false,
        activityDate: [],           //活动时间
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getActivityList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 活动时间选择
    activityDateSel(e){
      this.searchData.startTime =  e[0];            //活动开始时间
      this.searchData.endTime =  e[1];              //活动结束时间
    },
    // 表格内操作
    tableHandle(type, row) {
      switch (type) {
        case 1:     //查看
          this.$router.push(`/management/groupBuying/groupActivity/detail?groupActivityId=${row.groupActivityId}`);
          break;
        case 2:     //编辑
          this.$router.push(`/management/groupBuying/groupActivity/add?groupActivityId=${row.groupActivityId}`);
          break;
        case 3:     //结束
          this.$confirm('是否结束此拼团活动?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('management/groupActivityEnd',{//groupActivityList
              groupActivityId: row.groupActivityId
            }).then(res => {
              if(res.code == 0){
                this.$refs.list.init();
                this.$message.success('已经结束拼团')
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
          break;
        case 4:     //删除
          this.$confirm('是否删除此拼团活动?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('management/groupActivityDel',{//groupActivityList
              groupActivityId: row.groupActivityId
            }).then(res => {
              if(res.code == 0){
                this.$refs.list.init();
                this.$message.success('删除成功')
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
        default:
          break;
      }
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        activityName: "",         //活动名称
        status: "",               //活动状态
        startTime: "",            //活动开始时间
        endTime: "",              //活动结束时间
      }
      this.activityDate = [];           //活动时间
    },

    // 获取活动列表
    getActivityList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('management/groupActivityList',{//groupActivityList
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },
    // 跳转活动详情
    gotoTeamList(row){
      this.$router.push(`/management/groupBuying/groupOrder/list?activityId=${row.groupActivityId}`);
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
